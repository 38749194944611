import {
	fetchJSCXDataApi,
	fetchAdsResourcesApi,
	fetchJSCXTermsApi,
	fetchAllDomainsApi,
	fetchJSCXEstWatchApi,
	fetchJSCXPlanApi,
	confirmJSCXPlanApi,
	fetchJSCXDomainDirectionsApi,
	addJSCXDomainDirectionApi,
	updateJSCXDomainDirectionApi,
	batchConfirmJSCXPlansApi,
	delJSCXDomainDirectionApi,
	fetchJSCXTermDirectionsApi,
	addJSCXTermDirectionApi,
	updateJSCXTermDirectionApi,
	delJSCXTermDirectionApi,
	fetchJSCXCFOffersApi,
	updateJSCXCFOfferStatusApi,
	fetchDirectionsApi,
	fetchJSCXListTermAdjustPlanApi,
	confirmJSCXTermAdjustPlanApi
} from '@/apis/dataset.js'

export default {
	async getList(context, {params, trigger}) {
		return fetchJSCXDataApi(params, trigger)
	},
	// account campaign adset ad
	async getAccounts(context) {
		return fetchAdsResourcesApi(0)
	},
	async getCampaigns(context) {
		return fetchAdsResourcesApi(1)
	},
	async getAdsets(context) {
		return fetchAdsResourcesApi(2)
	},
	async getAds(context) {
		return fetchAdsResourcesApi(3)
	},
	async getTerms(context, {params, trigger}) {
		return fetchJSCXTermsApi(params, trigger)
	},
	async getDomains(context, params) {
		return fetchAllDomainsApi(params)
	},
	async getEstWatch(context, {params, trigger}) {
		return fetchJSCXEstWatchApi(params, trigger)
	},
	async fetchJSCXPlan(context, {params, trigger}) {
		return fetchJSCXPlanApi(params, trigger)
	},
	async confirmJSCXPlan(context, id) {
		return confirmJSCXPlanApi(id)
	},
	async batchConfirmJSCXPlans(context, ids) {
		return batchConfirmJSCXPlansApi(ids)
	},
	async fetchJSCXDomainDirections(context, {params, trigger}) {
		return fetchJSCXDomainDirectionsApi(params, trigger)
	},
	async addJSCXDomainDirection(context, body) {
		return addJSCXDomainDirectionApi(body)
	},
	async updateJSCXDomainDirection(context, {id, body}) {
		return updateJSCXDomainDirectionApi(id, body)
	},
	async delJSCXDomainDirection(context, id) {
		return delJSCXDomainDirectionApi(id)
	},
	async fetchJSCXTermDirections(context, {params, trigger}) {
		return fetchJSCXTermDirectionsApi(params, trigger)
	},
	async addJSCXTermDirection(context, body) {
		return addJSCXTermDirectionApi(body)
	},
	async updateJSCXTermDirection(context, {id, body}) {
		return updateJSCXTermDirectionApi(id, body)
	},
	async delJSCXTermDirection(context, id) {
		return delJSCXTermDirectionApi(id)
	},
	async fetchJSCXCFOffers(context, {params, trigger}) {
		return fetchJSCXCFOffersApi(params, trigger)
	},
	async updateJSCXCFOfferStatus(context, {id, flag}) {
		return updateJSCXCFOfferStatusApi(id, flag)
	},
	async fetchDirections(context) {
		return fetchDirectionsApi()
	},
	async fetchJSCXListTermAdjustPlan(context, params) {
		return fetchJSCXListTermAdjustPlanApi(params)
	},
	async confirmJSCXTermAdjustPlan(context, {id, date}) {
		return confirmJSCXTermAdjustPlanApi(id, date)
	}
}